export enum OSM {
    NODE = "node",
    WAY = "way",
    RELATION = "relation",
}

type OverpassCommon = {
    type: string;
    id: number;
    tags: Record<string, unknown>;
};

type coordinate = {
    lon: number;
    lat: number;
};

type center = {
    center: coordinate;
};

export type OsmNode = OverpassCommon &
    coordinate & {
        type: OSM.NODE;
    };

export type OsmWay = OverpassCommon &
    center & {
        type: OSM.WAY;
    };

export type OsmRelation = OverpassCommon &
    center & {
        type: OSM.RELATION;
    };

export type OverpassElement = OsmNode | OsmWay | OsmRelation;

export type OverpassResult = JSON & {
    elements: OverpassElement[];
    // eslint-disable-next-line camelcase
    osm3s: { copyrigh: string; timestamp_osm_base: string };
};
