







































































import { geoJSON, featureGroup, marker } from "leaflet";
import Vue from "vue";
import { mapState } from "vuex";

import type { BvModal } from "bootstrap-vue";
import type { FeatureCollection } from "geojson";
import type { Icon, Marker } from "leaflet";

import { OverpassQuery } from "@/where2meet/osm";
import { where2meet } from "@/where2meet";
import pointToFeature, { OSM2LatLon, mapMixin, QueryState } from "@/util";
import { parkIcon, cafeIcon, restaurantIcon } from "@/components/MapView.vue";
import QueryListItem from "@/components/QueryListItem.vue";
import { MapMutationTypes, MutationTypes } from "@/store/mutations";

import type { State } from "@/store/state";

export default Vue.extend({
    name: "Query",
    components: {
        QueryListItem,
    },
    mixins: [mapMixin],
    data() {
        return {
            loading: false as boolean,
            selected: "park" as string,
            options: [
                { text: "Park", value: "park" },
                { text: "Café", value: "cafe" },
                { text: "Restaurant", value: "restaurant" },
            ] as { text: string; value: string }[],
        };
    },
    computed: mapState({
        markers: state => (state as State).markers,
        coordinates: state => (state as State).coordinates,
        results: state => (state as State).results,
    }),
    methods: {
        removeListItem(index: number): void {
            this.$store.commit(MapMutationTypes.REMOVE_FROM_MAP, this.markers[index]);
            this.$store.commit(MutationTypes.REMOVE_MARKER, index);
            this.$store.commit(MutationTypes.REMOVE_COORDINATE, index);
        },
        onSearch(): void {
            if (this.coordinates.length < 2) {
                (this.$bvModal as BvModal).msgBoxOk(
                    "Mindestens zwei Standorte müssen hinzugefügt werden!",
                    { centered: true },
                );
                return;
            }
            if (this.results) {
                this.results.remove();
            }
            this.$store.commit(MutationTypes.CLEAR_RESULTS);
            this.$store.commit(MutationTypes.SET_QUERY_STATE, QueryState.NONE);
            const query = new OverpassQuery(
                `(${this.buildQuery()});`,
                featureGroup(this.markers).getBounds(),
            );
            this.loading = true;
            query
                .asJson()
                .then(queryResults => {
                    this.loading = false;
                    if (queryResults) {
                        const results = where2meet(
                            this.coordinates,
                            queryResults.elements,
                            "center",
                        );
                        if (results.length === 0) {
                            this.$store.commit(MutationTypes.SET_QUERY_STATE, QueryState.NO_RESULT);
                            return;
                        }
                        const features = results.splice(0, 5).map(result => {
                            const element = queryResults.elements[result.index];
                            const { lat, lon } = OSM2LatLon(element);
                            return pointToFeature(lon, lat, element.tags);
                        });
                        // eslint-disable-next-line @typescript-eslint/no-this-alias
                        const vm = this;
                        const resultDataset = geoJSON(
                            {
                                type: "FeatureCollection",
                                features,
                            } as FeatureCollection,
                            {
                                onEachFeature(feature, layer) {
                                    layer.bindPopup(`<h4>${feature.properties.name}</h4>`);
                                },
                                pointToLayer(_, latlng) {
                                    let icon: Icon;
                                    switch (vm.selected) {
                                        case "park":
                                            icon = parkIcon;
                                            break;
                                        case "cafe":
                                            icon = cafeIcon;
                                            break;
                                        case "restaurant":
                                            icon = restaurantIcon;
                                            break;
                                        default:
                                            return marker(latlng);
                                    }
                                    return marker(latlng, { icon });
                                },
                            },
                        );
                        this.$store.commit(MutationTypes.SET_RESULTS, resultDataset);
                        this.$store.commit(MapMutationTypes.ADD_TO_MAP, resultDataset);
                        this.$store.commit(MutationTypes.SET_QUERY_STATE, QueryState.SUCCESS);
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.$store.commit(MutationTypes.SET_QUERY_STATE, QueryState.FAILURE);
                });
            this.$emit("query:finished");
        },
        onReset(): void {
            if (this.results) {
                this.results.remove();
            }
            this.$store.commit(MutationTypes.CLEAR_COORDINATES);
            this.markers.forEach((mrk: Marker) => {
                mrk.remove();
            });
            this.$store.commit(MutationTypes.CLEAR_MARKERS);
            this.$store.commit(MutationTypes.CLEAR_RESULTS);
            this.$store.commit(MutationTypes.SET_QUERY_STATE, QueryState.NONE);
        },
        buildQuery(): string {
            const queryElements: string[] = [];
            switch (this.selected) {
                case "park":
                    queryElements.push(`nwr[leisure="${this.selected}"]["name"]`);
                    break;
                case "cafe":
                case "restaurant":
                    queryElements.push(`nwr[amenity="${this.selected}"]["name"]`);
                    break;
                // no default
            }
            return `${queryElements.join(";")};`;
        },
    },
});
