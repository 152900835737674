import Vue from "vue";
import Vuex from "vuex";

import type { Store as VuexStore, CommitOptions } from "vuex";

import { state } from "@/store/state";
import { mutations } from "@/store/mutations";

import type { State } from "@/store/state";
import type { Mutations, MapMutations } from "@/store/mutations";

Vue.use(Vuex);

export default new Vuex.Store<State>({
    state,
    mutations,
});

type Where2meetMutations = Mutations & MapMutations;

export type Store = Omit<VuexStore<State>, "commit"> & {
    commit<K extends keyof Where2meetMutations, P extends Parameters<Where2meetMutations[K]>[1]>(
        key: K,
        payload?: P,
        options?: CommitOptions,
    ): ReturnType<Where2meetMutations[K]>;
};
