import { latLng } from "leaflet";

import type { LatLng } from "leaflet";

import { OSM2LatLon, centroid, mean } from "@/util";

import type { OverpassElement } from "./osm";

export type Where2meetResult = { index: number; mean: number; center: number };

function coordsDistanceToOSM(
    coords: LatLng[],
    overpassData: OverpassElement[],
): Where2meetResult[] {
    const results: Where2meetResult[] = [];
    overpassData.forEach((data, index) => {
        const distances: number[] = [];
        const { lat, lon } = OSM2LatLon(data);
        coords.forEach(coord => distances.push(coord.distanceTo(latLng(lat, lon)) / 1000));
        results.push({
            index,
            mean: mean(distances),
            center: centroid(coords).distanceTo(latLng(lat, lon)),
        });
    });
    return results;
}

function sortBy(method: "center" | "mean", results: Where2meetResult[]): Where2meetResult[] {
    return results.sort((elem1, elem2) => {
        const prop1 = elem1[method];
        const prop2 = elem2[method];
        if (prop1 < prop2) {
            return -1;
        }
        if (prop1 > prop2) {
            return 1;
        }
        return 0;
    });
}

export function where2meet(
    coords: LatLng[],
    osmData: OverpassElement[],
    method: "center" | "mean" = "center",
): Where2meetResult[] {
    return sortBy(method, coordsDistanceToOSM(coords, osmData));
}
