import axios from "axios";

import type GeoJSON from "geojson";
import type { LatLngBounds } from "leaflet";

import pointFeature, { OSM2LatLon } from "@/util";

import type { OverpassResult } from "./types";

export class OverpassQuery {
    private query: string;

    private bbox: LatLngBounds;

    private output: string[] | undefined;

    private endpoint: string | undefined;

    constructor(query: string, bbox: LatLngBounds, output?: string[], endpoint?: string) {
        this.query = query;
        this.bbox = bbox;
        this.output = output || ["tags", "center", "qt"];
        this.endpoint = endpoint || "https://overpass-api.de/api/interpreter";
    }

    async asGeoJson(): Promise<GeoJSON.FeatureCollection | null> {
        const result = await this.exec();
        if (result) {
            const features = [] as GeoJSON.Feature[];
            result.elements.forEach(element => {
                const { lat, lon } = OSM2LatLon(element);
                features.push(pointFeature(lon, lat, element.tags));
            });
            return {
                type: "FeatureCollection",
                features,
            };
        }
        return null;
    }

    async asJson(): Promise<OverpassResult | null> {
        return this.exec();
    }

    private async exec(): Promise<OverpassResult | null> {
        try {
            const response = await axios.get(this.endpoint + this.buildQueryString());
            return response.data;
        } catch (error) {
            console.error(error);
        }
        return null;
    }

    private buildQueryString(): string {
        return `?data=[bbox][out:json];${this.query}out ${this.output?.join(
            " ",
        )};&bbox=${this.bbox.toBBoxString()}`;
    }
}

export default OverpassQuery;
