<template>
    <b-row no-gutters>
        <b-col order="2">
            <map-sidebar />
        </b-col>
        <b-col
            lg="9"
            order-lg="2"
            class="sticky-map-col"
        >
            <map-view class="mapview-height" />
        </b-col>
    </b-row>
</template>

<script>
import Vue from "vue";

import MapSidebar from "@/components/MapSidebar.vue";
import MapView from "@/components/MapView.vue";

export default Vue.extend({
    name: "Map",
    components: { MapSidebar, MapView },
});
</script>
<style scoped>
/* Fix for reducing extra space when using 100vh together with bootstrap navbar  */
@media screen and (min-width: 992px) {
    .mapview-height {
        height: calc(100vh - 3.55em);
    }
}
@media screen and (max-width: 991px) {
    .mapview-height {
        height: calc(55vh - 3.55em);
    }
    .sticky-map-col {
        position: sticky;
        top: 3.55em;
        z-index: 2;
    }
}
</style>
