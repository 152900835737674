import type {
    GeoJSON, LatLng, Layer, Map, Marker,
} from "leaflet";
import type { MutationTree } from "vuex";

import { QueryState } from "@/util";

import type { State } from "./state";

export enum MapMutationTypes {
    SET_MAP = "SET_MAP",
    ADD_TO_MAP = "ADD_TO_MAP",
    REMOVE_FROM_MAP = "REMOVE_FROM_MAP",
}

export enum MutationTypes {
    // Coordinates
    ADD_COORDINATE = "ADD_COORDINATE",
    REMOVE_COORDINATE = "REMOVE_COORDINATE",
    CLEAR_COORDINATES = "CLEAR_COORDINATES",
    // Markers
    ADD_MARKER = "ADD_MARKER",
    REMOVE_MARKER = "REMOVE_MARKER",
    CLEAR_MARKERS = "CLEAR_MARKERS",
    // RESULTS
    SET_RESULTS = "SET_RESULTS",
    CLEAR_RESULTS = "CLEAR_RESULTS",
    // Query
    SET_QUERY_STATE = "SET_QUERY_STATE",
}

export type MapMutations<S = State> = {
    [MapMutationTypes.SET_MAP](state: S, mapObject: Map): void;
    [MapMutationTypes.ADD_TO_MAP](state: S, obj: Layer): void;
    [MapMutationTypes.REMOVE_FROM_MAP](state: S, obj: Layer): void;
};

export type Mutations<S = State> = {
    [MutationTypes.ADD_COORDINATE](state: S, coord: LatLng): void;
    [MutationTypes.REMOVE_COORDINATE](state: S, index: number): void;
    [MutationTypes.CLEAR_COORDINATES](state: S): void;
    [MutationTypes.ADD_MARKER](state: S, marker: Marker): void;
    [MutationTypes.REMOVE_MARKER](state: S, index: number): void;
    [MutationTypes.CLEAR_MARKERS](state: S): void;
    [MutationTypes.SET_RESULTS](state: S, results: GeoJSON): void;
    [MutationTypes.CLEAR_RESULTS](state: S): void;
    [MutationTypes.SET_QUERY_STATE](state: S, queryState: QueryState): void;
};

export const mutations: MutationTree<State> & MapMutations & Mutations = {
    [MapMutationTypes.SET_MAP](state, mapObject) {
        state.mapObject = mapObject;
    },
    [MapMutationTypes.ADD_TO_MAP](state, obj) {
        if (state.mapObject) {
            obj.addTo(state.mapObject);
        }
    },
    [MapMutationTypes.REMOVE_FROM_MAP](state, obj) {
        if (state.mapObject) {
            obj.removeFrom(state.mapObject);
        }
    },
    [MutationTypes.ADD_COORDINATE](state, coord) {
        state.coordinates.push(coord);
    },
    [MutationTypes.REMOVE_COORDINATE](state, index) {
        state.coordinates.splice(index, 1);
    },
    [MutationTypes.CLEAR_COORDINATES](state) {
        state.coordinates = [];
    },
    [MutationTypes.ADD_MARKER](state, marker) {
        state.markers.push(marker);
    },
    [MutationTypes.REMOVE_MARKER](state, index) {
        state.markers.splice(index, 1);
    },
    [MutationTypes.CLEAR_MARKERS](state) {
        state.markers = [];
    },
    [MutationTypes.SET_RESULTS](state, results) {
        state.results = results;
    },
    [MutationTypes.CLEAR_RESULTS](state) {
        state.results = null;
    },
    [MutationTypes.SET_QUERY_STATE](state, currentState) {
        state.queryState = currentState;
    },
};
