






















/* eslint-disable global-require */
import Vue from "vue";
import "leaflet/dist/leaflet.css";

import { icon, latLng, marker } from "leaflet";
import { LMap, LTileLayer } from "vue2-leaflet";

import type { BaseIconOptions, LeafletMouseEvent } from "leaflet";
import type { LMap as LMapType } from "vue2-leaflet";
import { MapMutationTypes, MutationTypes } from "@/store/mutations";

const iconProps: BaseIconOptions = {
    iconSize: [32, 37],
    iconAnchor: [16, 37],
    popupAnchor: [0, -36],
};

export const parkIcon = icon({
    iconUrl: require("../assets/forest2.png"),
    ...iconProps,
});
export const cafeIcon = icon({
    iconUrl: require("../assets/coffee.png"),
    ...iconProps,
});
export const restaurantIcon = icon({
    iconUrl: require("../assets/restaurant.png"),
    ...iconProps,
});

export default Vue.extend({
    name: "MapView",
    components: {
        LMap,
        LTileLayer,
        // LMarker,
    },
    data() {
        return {
            zoom: 5,
            center: latLng(53, 9),
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            mapOptions: {},
            showMap: true,
        };
    },
    mounted() {
        Vue.nextTick().then(() => {
            const { mapObject } = this.$refs.map as LMapType;
            this.$store.commit(MapMutationTypes.SET_MAP, mapObject);
            mapObject.locate({ setView: true, maxZoom: 16 });
        });
    },
    methods: {
        addDefaultMarker(ev: LeafletMouseEvent): void {
            const mrk = marker(ev.latlng);
            this.$store.commit(MapMutationTypes.ADD_TO_MAP, mrk);
            this.$store.commit(MutationTypes.ADD_COORDINATE, ev.latlng);
            this.$store.commit(MutationTypes.ADD_MARKER, mrk);
        },
    },
});
