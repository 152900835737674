

























import Vue from "vue";
import Query from "./Query.vue";
import Result from "./Result.vue";

export default Vue.extend({
    name: "MapSidebar",
    components: { Query, Result },
    methods: {
        scrollToResult(resultElement: HTMLElement): void {
            resultElement.scrollIntoView();
        },
    },
});
