import type {
    GeoJSON, LatLng, Map, Marker,
} from "leaflet";

import { QueryState } from "@/util";

export const state = {
    mapObject: null as Map | null,
    coordinates: [] as LatLng[],
    markers: [] as Marker[],
    results: null as GeoJSON | null,
    queryState: QueryState.NONE,
};

export type State = typeof state;
