/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable global-require */
import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/fontawesome";
import L from "leaflet";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/css/tailwind.css";
import "./plugins/bootstrap-vue";

Vue.config.productionTip = false;

// eslint-disable-next-line no-underscore-dangle
delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app");
