





























/* eslint-disable camelcase */
import Vue from "vue";
import { LatLng } from "leaflet";

import { nominatimClient } from "@/where2meet/osm";
import { germanAddress } from "@/util";

export default Vue.extend({
    name: "QueryListItem",
    props: {
        coordinate: { type: LatLng, required: true },
    },
    data() {
        return {
            loading: false as boolean,
            location: "" as string,
        };
    },
    async mounted() {
        this.location = await this.getAddress(this.coordinate);
    },
    methods: {
        async getAddress(coordinate: LatLng): Promise<string> {
            this.loading = true;
            const { lat, lng: lon } = coordinate;
            try {
                const result = await nominatimClient.reverse({ lat, lon });
                return germanAddress(result);
            } catch (e) {
                return `${lat.toFixed(6)} | ${lon.toFixed(6)}`;
            } finally {
                this.loading = false;
            }
        },
    },
});
