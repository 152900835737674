




































import Vue from "vue";
import { mapState } from "vuex";

import type { FeatureCollection } from "geojson";
import type { Marker } from "leaflet";

import { mapMixin, QueryState } from "@/util";

import type { State } from "@/store/state";

export default Vue.extend({
    name: "Result",
    mixins: [mapMixin],
    data() {
        return {
            QueryState,
        };
    },
    computed: mapState({
        currentState: state => (state as State).queryState,
        resultProps: state => {
            const { results } = state as State;
            return results
                ? (results.toGeoJSON() as FeatureCollection).features.map(
                    feature => feature.properties,
                )
                : null;
        },
        resultMarkers: state => {
            const { results } = state as State;
            return results ? (results.getLayers() as Marker[]) : null;
        },
    }),
});
