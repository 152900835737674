/* eslint-disable camelcase */
import { latLng } from "leaflet";
import Vue from "vue";

import type { Feature, Point } from "geojson";
import type { LatLng, Marker } from "leaflet";
import type { ReverseResult } from "nominatim-client";

import { OSM } from "@/where2meet/osm/types";

import type { OverpassElement } from "@/where2meet/osm/types";

export default function pointFeature(
    lon: number,
    lat: number,
    properties: Record<string, unknown>,
): Feature<Point> {
    return {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [lon, lat],
        },
        properties: { ...properties },
    };
}

export function OSM2LatLon(element: OverpassElement): { lat: number; lon: number } {
    let lat: number;
    let lon: number;
    switch (element.type) {
        case OSM.NODE:
            ({ lat, lon } = element);
            break;
        case OSM.WAY:
        case OSM.RELATION:
            ({ lat, lon } = element.center);
            break;
        // no default
    }
    return { lat, lon };
}

export function centroid(points: LatLng[]): LatLng {
    const sum = (arr: number[]) => arr.reduce((a, c) => a + c, 0);
    const lngs = points.map(point => point.lng);
    const lats = points.map(point => point.lat);
    return latLng(sum(lats) / points.length, sum(lngs) / points.length);
}

export function mean(arr: number[]): number {
    return arr.reduce((a, c) => a + c, 0) / arr.length;
}

export function germanAddress(geocoded: ReverseResult): string {
    type geocodedWithHouseNumber = ReverseResult & {
        address: {
            house_number: string;
            town: string,
            village: string
        };
    };
    const {
        road, house_number, postcode, city, town, village,
    } = (geocoded as geocodedWithHouseNumber)
        .address;
    if (!road) throw new Error("Cannot build address string without road name.");
    const roadAndHouseNumber = house_number ? `${road} ${house_number}` : `${road}`;
    const locality = `${postcode || ""} ${city || town || village || ""}`;
    return `${roadAndHouseNumber}, ${locality}`;
}

export const mapMixin = Vue.extend({
    methods: {
        highlightMarker(index: number, markers: Marker[]): void {
            markers[index].setOpacity(0.5);
        },
        unhighlightMarker(index: number, markers: Marker[]): void {
            markers[index].setOpacity(1);
        },
        flyToMarker(index: number, markers: Marker[], zoom = 16): void {
            const { mapObject } = this.$store.state;
            if (mapObject && markers) {
                mapObject.flyTo(markers[index].getLatLng(), zoom);
            }
        },
    },
});

export enum QueryState {
    NONE,
    SUCCESS,
    NO_RESULT,
    FAILURE,
}
